<template>
  <div>
    <!--begin::Content header-->
    <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <!--<span class="font-weight-bold font-size-3 text-dark-60">
        Don't have an account yet?
      </span>-->
      <!--<router-link
        class="font-weight-bold font-size-3 ml-2"
        :to="{ name: 'register' }"
      >
        Sign Up!
      </router-link>-->
    </div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Acessar</h3>
        <p class="text-muted font-weight-semi-bold">
          Entre com seu usuário e senha
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div role="alert" class="alert alert-info">
          <div class="alert-text">
            Acesse usando seu <strong>usuário</strong> e
            <strong>senha</strong> ou com sua <strong>chave</strong> para continuar.
          </div>
        </div>

        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-0"
          label="Chave"
          label-for="example-input-0"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-0"
            name="example-input-0"
            v-model="$v.form.chave.$model"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-1"
          label="Usuário"
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.usuario.$model"
            mudado_state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label="Senha"
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.senha.$model"
            mdado_state="validateState('senha')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            A Senha é Obrigatória.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center">
          <div></div>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary right font-weight-bold px-9 py-4 my-3 font-size-3">
            Acessar
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
  .spinner.spinner-right {
    padding-right: 3.5rem !important;
  }
</style>

<script>
	import {mapState} from "vuex";
	import {LOGIN, LOGIN_LINK, LOGOUT} from "@/core/services/store/auth.module";

	import {validationMixin} from "vuelidate";
	import {minLength, required} from "vuelidate/lib/validators";
	import ApiService from "../../../core/services/api.service";

	export default {
		mixins: [validationMixin],
		name: "login",
		data() {
			return {
				// Remove this dummy login info
				form: {
					usuario: "",
					senha: "",
					chave: ""
				}
			};
		},
		validations: {
			form: {
				usuario: {
					//required
				},
				senha: {
					//required,
					minLength: minLength(3)
				},
				chave: {
					minLength: minLength(3)
				}
			}
		},
		methods: {
			validateState(name) {
				const {$dirty, $error} = this.$v.form[name];
				return $dirty ? !$error : null;
			},
			resetForm() {
				this.form = {
					usuario: null,
					senha: null,
					chave: null
				};

				this.$nextTick(() => {
					this.$v.$reset();
				});
			},
			onSubmit() {
				this.$v.form.$touch();
				if (this.$v.form.$anyError) {
					return;
				}

				const usuario = this.$v.form.usuario.$model;
				const senha = this.$v.form.senha.$model;
				const chave = this.$v.form.chave.$model;

				// clear existing errors
				this.$store.dispatch(LOGOUT);

				// set spinner to submit button
				const submitButton = this.$refs["kt_login_signin_submit"];
				submitButton.classList.add("spinner", "spinner-light", "spinner-right");

				this.$store
					.dispatch(LOGIN, {usuario, senha, chave})
					// go to which page after successfully login
					.then((data) => {
						submitButton.classList.remove(
							"spinner",
							"spinner-light",
							"spinner-right"
						);
						if (data.user)
							this.$router.push({name: "telesaude"})
						else
							this.$router.push({name: "convidado"})
					}).catch(e => {
					console.log('Um erro ocorreu......');
					console.log(e);
				});

			}
		},
		computed: {
			...mapState({
				errors: state => state.auth.errors
			})
		},
		mounted() {
			var param1 = this.$route.query.p;
			if (param1) {
        this.$store.dispatch(LOGIN_LINK,param1)
        .then((ret) => {
        	this.$router.push({name:'convidado'})
        })
        .catch();
				/*
				ApiService
					.get('p',param1)
					.then(ret => {
            console.log(ret);
					})
					.catch(e => {
						alert('Erro: ' + e);
						console.log(e);
					});
*/

			}
		}
	};
</script>
